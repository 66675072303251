.arabic {
    .__arb {
        direction: rtl;
    }

    .__icon {
        left: 16px !important;
        right: auto !important;
    }
}


.__input_sec {
    width: 100%;
    position: relative;
    border-radius: 55px;
    display: flex;
    justify-content: end;
    gap: 10px;

    input {
        padding: 13px 20px;
        background: #fff;
        color: #000;
        border-radius: 50px;
        width: 110px;
        font-size: 20px;
        outline: none;
        height: 55px;
        border: none;
        transition: 0.4s;
        &:focus {
            width: calc(100% - 55px);
        }
    }

    button {
        padding: 15px;
        outline: none;
        border: none;
        height: 55px;
        width: 55px;
        border-radius: 55px;
        background: #e3a83a;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }
}