@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: 'Readex Pro', sans-serif;
    position: relative;
}

.__dark {
    background-color: #0D0D0D;
    color: #fff;
}

.__light {
    background-color: #fff;
    color: #0D0D0D;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px
}

::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #edf2f7
}

::-webkit-scrollbar-thumb {
    background: #989fa7;
    border-radius: 100vh;
    border: 3px solid #edf2f7
}

::-webkit-scrollbar-thumb:hover {
    background: #484d52
}

.content-wrapper {
    min-height: 85vh;
    padding-bottom: 30px;
}

.__innerTopPad {
    padding-top: 120px;
}

.__common_grid_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 30px;
    margin: 30px 0px 15px;
}

.__common-head {
    font-size: 28px;
    font-weight: 600;
    line-height: 29px;
    margin-bottom: 20px;
}

.__common-head-2 {
    font-size: 42px;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 15px;
}

.__videos-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
}

.__photos-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    grid-gap: 30px;

    img {
        height: 170px;
        object-fit: cover;
        width: 100%;
        box-shadow: 0px 0px 10px #0005;
        border: 2px solid #fff;
        border-radius: 10px;
        cursor: pointer;
    }
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    border-radius: 10px;
    overflow: hidden;
    background: #000;
    box-shadow: 0px 0px 10px #0005;
    border: 2px solid #fff;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.__banner_set {
    min-height: 600px;
}

.imageLoader {
    background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
    animation: 1.5s shine linear infinite;
    background-size: 200% 100%;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}


.arabic {

    .__common_grid_list,
    .__main_content,
    .__related_content,
    .__article-list {
        direction: rtl;
    }

    .__icon_set a {
        margin-left: 30px;
        margin-right: 0 !important;
    }

}

.arabic {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    button {
        direction: rtl;
        font-family: 'Readex Pro', sans-serif;
    }

    .__custom_caleder_btn {
        left: 0 !important;
        right: auto !important;
    }

    .__cover_image {
        direction: rtl;
    }

    .__more_img {
        margin-left: 20px;
        margin-right: 0px !important;
    }

    .__more_img_arb {
        direction: rtl;
    }

    .__add_comment_wrap .__comment_adding img {
        margin-right: 0px !important;
        margin-left: 15px;
    }
    .ant-tabs-tab+.ant-tabs-tab {
        margin-left: 0px !important;
        margin-right: 32px !important;
    }
    .__tabs_outline{
        .ant-tabs-tab+.ant-tabs-tab {
            margin-right: initial !important;
        }   
    }
}


.__spin_color {
    color: #e3a83a;

    .ant-spin-dot-item {
        background-color: #e3a83a !important;
    }
}

.__spin_color_white {
    color: #fff;

    .ant-spin-dot-item {
        background-color: #fff !important;
    }
}


.ant-modal-mask {
    background-color: #000000ba !important
}


._custom_input {
    input {
        width: 100% !important;
        display: block;
        border: 1px solid #969fad;
        outline: none;
        background-color: #fff;
        height: 48px;
        font-size: 1.5rem;
        padding: 0 10px;
        border-radius: 4px;
        font-weight: 400;
        color: #6e6e6e;
        margin: 7px;
    }
}

.__otp_content {
    text-align: center;

    h1 {
        font-size: 18px;
    }
}


.__common_model_class {

    .ant-modal-content {
        background: #0D0D0D;
        color: #fff;
    }

    .ant-modal-close {
        color: #fff;
    }

    .__common_model_content {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;

        .____common_model_class_head {
            font-size: 22px;
            font-weight: 400;
            margin-bottom: 30px;
        }

        .____common_model_class_desc {
            font-size: 17px;
            font-weight: 300;
        }

    }
}

.__pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        border: 1px solid #e99800;
        border-radius: 5px;
        color: #e99800;
        background: transparent;
        font-size: 18px;
        line-height: 1;
        margin: 0;
        height: 40px;
        width: 120px;
        font-weight: bold;
        margin-top: 20px;
    }
}


.__add_comment_wrap {
    margin: 10px 0;

    .__comment_adding {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;

        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 60px;
            margin-right: 15px;
        }

        div {
            width: 100%;
            text-align: center;
        }
    }
}


.__comment_wrap {
    display: flex;
    margin-bottom: 25px;

    img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 60px;
        background: #3c3c3c;
    }

    h1 {
        font-size: 22px !important;

        span {
            font-size: 13px;
            margin: 0 10px;
            color: #aaa;
            font-weight: 400;
        }
    }

    p {
        font-size: 16px !important;
        color: #666 !important;
        margin-top: 5px !important;
        margin-bottom: 0 !important;
    }

}
.__com-ed-wrap {
    display: flex;
    justify-content: space-between;
    grid-gap: 30px;

    .__com_actions {
        display: flex;
        align-items: start;
        grid-gap: 5px;

        button {
            border: 0;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            background: #586e30;
            color: #ffffff;
        }
    }
}

.ant-tabs-tab+.ant-tabs-tab {
    margin-left: 32px !important;
}

.__tabs_outline{
    .ant-tabs-tab+.ant-tabs-tab {
        margin-left: initial !important;
    }   
}

.mySwiper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .swiper-button-prev,
    .swiper-button-next {
        z-index: 1111;
        width: 40px;
        height: 40px;
        background: #000000cf;
        border-radius: 50%;
        color: white;
        font-weight: 700;
        outline: 0;
        transition: background-color .2s ease, color .2s ease;

        &::after {
            font-size: 16px;
        }
    }

    .swiper-button-prev {
        &:after {
            position: relative;
            left: -1px;
        }
    }

    .swiper-button-next {
        &:after {
            position: relative;
            left: 1px;
        }
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 10px;
        right: auto;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 10px;
        left: auto;
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
        opacity: 0;
        cursor: auto;
        pointer-events: none;
    }
}


._cht_full {
    margin-top: 15px;
    margin-bottom: 35px;
    // background-color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;

    .__faq{
        color: #fff;
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 5px;
        cursor: pointer;
        max-width: max-content;
        img{
            filter: invert(1);
        }
    }

    .__chat_heading {
        margin-top: 0px;
        margin-bottom: 15px;
        font-size: 20px;
    }

    .__input_gpt {
        position: relative;

        button {
            position: absolute;
            top: 0;
            right: 0;
            min-width: 120px;
            width: 80px;
        }
    }

    .__resp_gpt {
        font-size: 15px;
        margin-top: 15px;
        font-weight: 300;
        margin-bottom: 0px;
        line-height: 1.5;
        padding: 10px 30px;

        h3 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
            text-transform: capitalize;
        }

        p {
            font-size: 15px;
            font-weight: 300;
            line-height: 1.8;
            text-align: justify;
        }
    }

}

.arabic {
    ._cht_full {

        .__input_gpt {

            button {
                position: absolute;
                top: 0;
                right: auto;
                left: 0;
                min-width: 120px;
                width: 80px;
            }
        }

    }
}