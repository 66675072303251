.terms_and_conditions {
    ul {
        color: #666;
        font-size: 14px;
        text-align: justify;
    }

    p {
        text-align: center;
        font-size: 17px;
        font-style: italic;
    }

    h1 {
        font-size: 22px;
        text-align: center;
        text-decoration: underline;
        margin: 0;
    }
    .__terms{
        color: #666;
        font-size: 14px;
        text-align: justify;
        p{
            font-size: 14px;
            direction: ltr;
            margin: 0;
            text-align: justify;
        }
    }
}