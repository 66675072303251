.__description {
    display: flex;
    flex-direction: column;
    .__art_pic{
        margin: 30px 0;
        width: 350px;
        height: 350px;
        object-fit: cover;
        cursor: pointer;
        border-radius: 350px;
    }
    .__rate {
        color: #e3a83a !important;

        .ant-rate-star-zero {

            .ant-rate-star-first,
            .ant-rate-star-second {
                color: #cccccc38;
            }
        }
    }

    .__movie_origin {
        font-size: 17px;
        font-weight: 300;
        margin-top: -10px;
        color: #ccc;
        margin-bottom: 20px;
    }
}

.__tabs {
    font-size: 20px;
    font-weight: 600;
    color: #fff;

    .__head {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
    }

    .ant-tabs-tab-active {
        .__head {
            color: #e3a83a;
            transition: 0.4s;
        }
    }

    .ant-tabs-ink-bar {
        background: #e3a83a;
        transition: 0.4s;
    }
}

.__img-txt-list {
    grid-gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    margin-top: 15px;

    .__img-txt-round {
        text-align: center;
        cursor: pointer;

        img {
            width: 110px;
                height: 110px;
                object-fit: cover;
                border-radius: 140px;
                box-shadow: 0px 0px 10px #000;
                font-size: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin: 0 auto;
        }

        h2 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 3px;
            margin-top: 10px;
        }

        p {
            font-size: 14px;
            margin-bottom: 0px;
            color: #ffffff7d;
        }
    }
}

.__links {
    display: flex;
    grid-gap: 10px;

    a {
        border: 1px solid;
        color: #fff;
    }
}