.__subBlock {
    padding: 50px 30px;
    padding-bottom: 40px;
    border: 1px solid #ffffff70;
    border-radius: 25px;
    position: relative;

    h1 {
        font-size: 30px;
        margin: 0;
        margin-bottom: 5px;
    }

    h2 {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .recommend {
        padding: 3px 7px;
        background-color: #e3a83a;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
        border-radius: 10px;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        letter-spacing: 1px;
        padding-top: 4px;

    }
    p{
        display: flex;
        align-items: center;
    }
}
.error_mg {
    text-align: center;
    color: red;
}