.__footer {
    background: #151515;
    width: 100%;
    color: #fff;
    padding: 40px 0;
    padding-bottom: 20px;

    .__foot_links {
        display: flex;
        flex-direction: column;

        a {
            color: #fff;
            font-weight: 300;
            text-decoration: none;
            margin-bottom: 20px;
            transition: 0.4s;

            @media screen and (max-width: 555px) {
                margin-bottom: 10px;
            }

            &:hover {
                color: #ffffff9e;
            }
        }
    }

    .__copywright {
        padding: 15px 0;
        background: #000;
    }

}

.__social {
    display: flex;
    align-items: center;
    justify-content: end;
    grid-gap: 16px;

    a {
        font-size: 23px;
        cursor: pointer;
        text-decoration: none;
        line-height: 1;
        display: flex;
        color: #fff !important;
        transition: 0.4s;

        &:hover {
            color: #e3a83a !important;
        }
    }
}

.__sub_s {
    display: grid;
    align-items: center;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    margin-top: 15px;

    @media screen and (max-width: 555px) {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    a {
        text-decoration: none;
    }

    button{
        @media screen and (max-width: 555px) {
         min-width: 94vw;
        }
    }
}

.__lgo_h {
    width: 70px;
    border-radius: 10px;

    @media screen and (max-width: 555px) {
        width: 40px;
    }
}

.__lgo_text {
    height: 20px;

    @media screen and (max-width: 555px) {
        height: 12px;
    }
}