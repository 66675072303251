.__home_banner {
    position: relative;

    img {
        width: 100%;
        height: 80vh;
        object-fit: cover;
    }

    .__bnr_content {
        position: absolute;
        bottom: 0px;
        padding-bottom: 8%;

        img {
            width: 240px;
            height: 103px;
            margin-bottom: 15px;
        }

        h2 {
            font-size: 4rem;
            width: 50vw;
            color: #f6c637;
            text-shadow: 0 0 114px #000;

            @media screen and (max-width: 555px) {
                font-size: 1.5rem;
                width: 80vw;
            }
        }

        h4 {
            font-size: 21px;
            font-weight: 400;
            letter-spacing: 0em;
            width: 50vw;
            line-height: 30px;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            flex: 1 1;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 20px;

            @media screen and (max-width: 555px) {
                font-size: 15px;
                line-height: 1.2;
                width: 80vw;
            }
        }

        .__bnr-actions {
            display: flex;
            grid-gap: 0px;
            margin-top: 30px;

            button {
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: 600;
                line-height: 21px;
                text-align: left;
                height: 40px;
                background-color: #f4b93940;
                text-shadow: 0px 0px 10px #0006;
            }
        }
    }
}

.__slider_sec {
    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 210px;
        object-fit: cover;
        border-radius: 5px;
        overflow: hidden;
        min-width: auto;
    }
}

.__lef__back {
    width: 100%;
    height: 100%;
    background: linear-gradient(70deg, black, transparent);
    position: absolute;
    left: 0;
    top: 0;
}

.arabic {
    .__lef__back {
        background: linear-gradient(290deg, black, transparent);
        left: auto;
        right: 0;
    }

    .__bnr_content {
        right: 0;
        direction: rtl;
    }
}

.__bottom__black{
    width: 100%;
    height: 10px;
    background: linear-gradient(0deg, #0d0d0d, transparent);
    position: absolute;
    left: 0;
    bottom: 0;
}