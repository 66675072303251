.privacy {
    line-height: 1.5;
    padding: 30px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    min-height: 60vh;
}

.static-page {
    max-width: 1000px;
    margin: 0 auto;
    p {
        font-weight: 300;
        margin-bottom: 20px;
        line-height: 25px;
    }

    b, strong, li {
        line-height: 25px;
    }
}

