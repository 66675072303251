.__selec_sec {
    display: flex;
    align-items: center;
    background: #fff5;
    border-radius: 50px;

    h6 {
        white-space: pre;
        padding: 16px;
        margin: 0;
    }

    .__selec {
        &:hover {
            .ant-select-selector {
                border-color: #fff !important;
            }

        }

        .ant-select-selector {
            width: 100%;
            height: 55px !important;
            padding: 10px 20px !important;
            font-size: 20px;
            border-radius: 50px;
        }

    }
}


.__search_sec {
    display: flex;
    align-items: center;

    input {
        padding: 13px 20px;
        background: #fff;
        color: #000;
        border-radius: 50px;
        font-size: 20px;
        outline: none;
        height: 55px;
        border: none;
        transition: 0.4s;
        width: calc(100% - 55px);
    }

    button {
        padding: 15px;
        outline: none;
        border: none;
        height: 55px;
        width: 55px;
        border-radius: 55px;
        background: #e3a83a;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }
}

.__res_grid_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-gap: 40px;
    margin: 30px 0px 15px;

    .__singl_res_item {
        text-align: center;
        cursor: pointer;
        transition: 0.4s;
        position: relative;

        &:hover {
            filter: saturate(1.4);

            img {
                transition: 0.4s;
                transform: scale(1.02);
            }
        }

        img {
            min-width: 100%;
            min-height: 250px;
            width: 100%;
            height: 250px;
            object-fit: cover;
            border-radius: 20px 0px;
        }

        h1 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 3px;
            margin-top: 10px;
        }


        h5 {
            font-size: 14px;
            margin-bottom: 0px;
            color: #ffffff7d;
        }

        a {
            font-size: 16px;
            color: #ffc959 !important;
            cursor: pointer;
        }

        .__premium {
            border-radius: 54px;
            background: #FFC107;
            color: #000000;
            font-weight: bold;
            position: absolute;
            top: 6px;
            right: 6px;
            height: 40px;
            width: 40px;
            font-size: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 0px 10px #000;
        }
    }


}