header {
    position: fixed;
    display: flex;
    width: 100%;
    z-index: 11;
}

.header_black {
    background: linear-gradient(180deg, #000000f5, #000000b8) !important;
}

.__header {
    height: 80px;
    background: linear-gradient(180deg, #000000f5, transparent);
    padding: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;

    .__logo {
        height: 50px;
        object-fit: contain;
        border-radius: 8px;
        cursor: pointer;


        @media screen and (max-width: 555px) {
            height: 25px;
        }
    }

    .__logo_text {
        width: 330px;
        cursor: pointer;
        @media screen and (max-width: 555px) {
            width: 165px;
        }
    }

    .__menu {
        color: #fff;
        flex: 8;
        text-align: center;

        @media screen and (max-width: 999px) {
            display: none;
        }

        a {
            font-size: 15px;
            font-weight: 300;
            margin: 0 20px;
            cursor: pointer;
            color: #fff;
            text-decoration: none;
            transition: 0.4s;
        }

        .active {
            border-bottom: 2px solid #E1AB2A;
            padding-bottom: 10px;
        }
    }


    .__dr_menu {
        display: flex;
        align-items: center;
        flex: 2;

        button {
            border: none;
            background: none;
            margin-right: 10px;
            outline: none;
            color: #fff;
        }

        h2 {
            font-size: 26px;
            color: #E1AB2A;
            font-weight: 400;
            font-family: 'Mohave', sans-serif;
            margin: 0;
        }
    }

    .__profile_sec {
        display: flex;
        justify-content: end;
        align-items: center;
        grid-gap: 10px;
        font-size: 20px;
        flex: 2;

        .__prof-btn {
            color: #fff;
            text-transform: capitalize;
            padding: 2px;
            border: 1px solid;
            border-radius: 100px;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            line-height: 1
        }

        .__lng {
            color: #fff;
            font-size: 16px;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.__drawer_menu {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    background: #212121 !important;
    position: relative;

    a {
        margin-bottom: 20px;
        font-size: 20px;
        cursor: pointer;
        text-decoration: none;
        width: max-content;
        display: flex;
        align-items: center;
        font-family: 'Readex Pro';
        color: #fff;

        svg {
            margin: 0 10px;
        }

        &:hover {
            color: #ffffff7a;
        }
    }

    .active {
        text-decoration: none;
        color: #f0b12f;
    }

    .__social_icons {
        color: #bfb7b7;
        text-align: center;
        width: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: -24px;
        border-top: 1px solid #cccccc47;
        padding-top: 10px;

        h2 {
            font-size: 17px;
            margin-bottom: 15px;
            color: #fff;
        }

        h5 {
            font-size: 13px;
            margin: 0;
        }

        div {
            display: flex;
            grid-gap: 25px;

            a {
                &:hover {
                    color: #e3a83a !important;
                }
            }
        }
    }
}

.lang_btn {
    background: none;
    color: #fff;
    font-size: 14px;
    border: none;
}

.arabic {
    .__drawer_menu {
        direction: rtl;
    }
}

.__divider {
    width: 100%;
    height: 1px;
    background-color: #cccccc47;
    margin-bottom: 15px;
}

.__drw_inr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}