.__login_modal {

    .ant-modal-content {
        background: #0D0D0D;
        color: #fff;
    }
    .ant-modal-close{
        color: #fff;
    }

    .__log_content {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;

        .__loginhead {
            font-size: 26px;
            font-weight: 400;
            margin-bottom: 30px;
        }

        .__facebook {
            height: 54px;
            width: 345px;
            border-radius: 10px;
            background: #1877F2;
            color: #fff;
            border: 0;
            font-size: 20px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0em;
            display: flex;
            align-items: center;
            justify-content: center;
            grid-gap: 15px;
        }

        .__google {
            height: 54px;
            width: 345px;
            border-radius: 10px;
            font-size: 20px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0em;
            display: flex;
            align-items: center;
            justify-content: center;
            grid-gap: 15px;
            border: 0;
        }

        .__forget {
            width: 100%;
            text-align: right;
            margin-top: 15px;

            a {
                font-size: 16px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                color: #ACACAC;
            }
        }

        .__new_signup {
            font-size: 16px;
            font-weight: 300;
            color: #ACACAC;
            text-align: left;
            width: 100%;
            margin: 0;
            a {
                font-size: 18px;
                color: #fff;
                font-weight: 500;
            }
        }

        .__border {
            background: #1e1e1e;
            width: 100%;
            height: 1px;
            margin-top: 25px;
            margin-bottom: 25px;
        }

    }
}