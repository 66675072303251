.__artist_single {
    text-align: center;
    cursor: pointer;
    transition: 0.4s;

    &:hover {
        filter: saturate(1.4);

        img {
            transition: 0.4s;
            transform: scale(1.02);
        }
    }

    img {
        min-width: 260px;
        min-height: 200px;
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: 0px 0px 10px #ffc1074a;
    }

    h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 3px;
        margin-top: 10px;
    }

    p {
        font-size: 14px;
        margin-bottom: 0px;
        color: #ffffff7d;
    }

}

.__artist_grid_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 40px;
    margin: 30px 5px 15px;
}