.__reset_modal {

    .ant-modal-content {
        background: #0D0D0D;
        color: #fff;
    }

    .ant-modal-close {
        color: #fff;
    }

    .__reset_content {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;

        .____reset_head {
            font-size: 26px;
            font-weight: 400;
            margin-bottom: 30px;
        }

    }
}