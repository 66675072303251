.__ico{
    margin-left: 10px;
    display: flex;
    font-size: 20px;
}

.arabic{
    .__ico{
        margin-left: 0px !important;
        margin-right: 10px;
    }
}