.__single_set {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #fff3;

    h1 {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .__sin__item {
        text-align: center;
        cursor: pointer;

        img {
            width: 100%;
            height: 150px;
            object-fit: cover;
            border-radius: 5px;
        }

        h2 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 3px;
            margin-top: 10px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 41px;
        }

        h6 {
            font-size: 14px;
            margin-bottom: 0px;
            color: #ffffff7d;
        }

        button {
            background: #e99800;
            color: #000;
            width: 100%;
            padding: 3px;
            border: 0;
            margin-top: 5px;
            font-size: 14px;
        }
    }
}

.__pre-btn {
    border: 1px solid #e99800;
    border-radius: 5px;
    color: #e99800;
    background: transparent;
    padding: 5px 10px;
}

.__pre_nom_list {
    grid-gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    margin-top: 15px;
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;

    .pre_nom_single {
        cursor: pointer;

        img {
            width: 110px;
            height: 110px;
            object-fit: cover;
            border-radius: 5px;
            font-size: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 0 auto;
        }

        h2 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 3px;
            margin-top: 10px;
        }

        p {
            font-size: 14px;
            margin-bottom: 0px;
            color: #ffffff7d;
        }
    }

}

.__prn_mnt {
    background: #e99800;
    color: #000;
    width: 100%;
    padding: 3px;
    border: 0;
    margin-top: 5px;
    font-size: 14px;
}
