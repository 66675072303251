.link_class img {
    width: 25px !important;
    margin: 0 5px;
    height: 25px !important;
    border-radius: 25px;
}


.screenShots {
    margin-top: 20px;
}

.screenShots img {
    width: 140px;
    margin: 15px;
}

.arabic .link_class{
    direction: rtl;
}